// IMPORTS
@import (reference) "../settings.less";

// USER CUSTOM MODAL
.user-modal-content {
    display: none;
}

.main-page-content-wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--rowGap);
}

.main-banners-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

// MAIN BANNER
.main-banner {
    width: 100%;
    margin: 0;
    border-radius: var(--borderRadius);

    a {
        display: block;
    }

    .swiper-slide {
        width: 100%;
        aspect-ratio: 1110 / 400;
        position: relative;
    }

    .banner-img-wrapper {
        position: absolute;
        inset: 0;
    }

    img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
    }

    b {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        bottom: 48px;
        color: white;
        background-color: rgba(62, 125, 7, 0.9);
        font-size: 24px;
        padding: 14px;
        text-align: center;
        min-width: 392px;
        border-radius: var(--borderRadius);
        width: fit-content;
        max-width: 90%;
    }
}

.swiper-pagination.banner-pagination {
    position: absolute;
    bottom: 0;
    width: auto;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: fit-content;
    display: flex;

    .swiper-pagination-bullet {
        border-radius: 0;
        opacity: 1;
        margin: 0;
        width: 30px;
        height: 48px;
        position: relative;
        background-color: transparent;
        transition: width .2s ease-in-out;

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 3px;
            width: calc(100% - 6px);
            background-color: white;
            height: 4px;
            transform: translateY(-50%);
            border-radius: 2px;
            box-shadow: 0 0 1px white;
        }

        &.swiper-pagination-bullet-active {
            width: 38px;

            &:after {
                background-color: @main-color;
            }
        }
    }
}

// STATIC BANNER
.static-banner {
    .grid();
    gap: 24px 31px;
    grid-template-columns: repeat(4, minmax(0, 1fr));

    div {
        display: flex;
        flex-direction: column;
        border: 1px solid @border-color;
        border-radius: var(--borderRadius);
        align-items: center;
        text-align: center;
        padding: 16px;
        font-size: 14px;
        line-height: 1.2;
    }

    figure {
        width: 48px;
        height: 48px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & + * {
            margin-top: 8px;
        }
    }

    img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }

    b {
        color: @main-color;

        & + span {
            margin-top: 4px;
        }
    }

    span {
        color: @header-color;
    }
}

// MAIN PAGE PRODUCTS
.main-page-products-wrapper {

    .headline {
        text-align: center;
    }
}